.header-bar {
    background-color: #ff9900;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    color: #fff;
    font-family: courier;
}

.header-bar > div {
    text-align: center;
    font-size: 20px;
}

.meme-container {
    background-color: #fff;
    display: inline-block;
    padding: 8px;
    margin: 8px;
}

.meme-title-container {
    padding-bottom: 4px;
}

.meme-container > * > img {
    height: 100%;
    width: 100%;
}

.meme-name {
    font-size: 16px;
    font-family: courier;
    font-weight: bold;
}

.meme-votes {
    display: inline-block;
    font-size: 24px;
    font-family: courier;
    font-weight: bold;
    color: #ff9900;
    margin-right: 4px;
}

.meme-author {
    font-size: 12px;
    font-family: courier;
}

.log-out {
    stroke: #fff;
    cursor: pointer;
}

.star-base {
    margin-top: -8px;
    stroke: #ff9900;
    fill: #ff9900;
    fill-opacity: 0;
    cursor: pointer;
}

.star-voted {
    fill-opacity: 1;
}

.star-not-voted:hover {
    fill-opacity: 0.3;
}

.size-1of3 {
    width: 33.33%;
}

.memes-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header-image {
    height: 40px;
    border-radius: 3px;
    margin: 2px;
}

.modal-open {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-selector {
    font-family: courier;
    font-size: 32px;
    text-align: center;
    user-select: none;
}

.selector-item {
    border: 1px solid #ff9900;
    cursor: pointer;
}

.selector-item-selected {
    background-color: rgba(255, 153, 0, 0.3);
    text-decoration: underline;
    text-decoration-color: #ff9900;
}

.create-meme {
    font-family: courier;
    font-size: 24px;
    text-align: center;
    user-select: none;
}

.create-meme-component {
    margin: 8px;
    display: inline-block;
    background-color: #fff;
    padding-top: calc(100% - 16px);
    position: relative;
    cursor: pointer;
}

.create-meme-internal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.console-spinner {
    font-family: courier;
    font-weight: 900;
    text-align: center;
}

.modal-view {
    background-color: #f4f4f4;
    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.3);
    border: 4px solid #ff9900;
}

.landing-page {
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #ff9900;
    display: flex;
    font-family: courier;
}

.header-title {
    font-size: 3vmin;
}
